export const toRad = (x: number) => (x * Math.PI) / 180;
export const toDeg = (x: number) => (x * 180) / Math.PI;

export const roughEquinoxDeg = ((31 + 28 + 1 / 4 + 22) / 365.25) * 360;

export const anglesDays = Array.from({ length: 365 }, (_, i) =>
  toRad((i / 365) * 360),
);
export const anglesMonths = (() => {
  const monthLengths = [31, 28 + 1 / 4, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const totalDays = monthLengths.reduce((sum, length) => sum + length, 0);

  let cumulativeDays = 0;
  return monthLengths.map((length) => {
    cumulativeDays += length;
    return toRad((cumulativeDays / totalDays) * 360 - roughEquinoxDeg);
  });
})();
