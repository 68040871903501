import React from "react";
import { PlanetSVGProps } from "./../types";

const Jupiter: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 143.9653}, ${height / 143.96532})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-442.11466,455.04132)"
        id="g8"
      >
        <path
          d="m 439.56,287.294 c 0,-29.816 -24.17,-53.987 -53.987,-53.987 -29.816,0 -53.987,24.171 -53.987,53.987 0,29.816 24.171,53.987 53.987,53.987 29.817,0 53.987,-24.171 53.987,-53.987"
          style={{
            fill: "#ddc3b2",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path170"
        />
        <path
          d="m 437.12,271.202 c -4.153,0.342 -9.207,0.64 -14.939,0.878 -4.198,0.174 -8.209,1.651 -11.634,4.086 -1.952,1.389 -4.472,2.226 -7.226,2.226 -3.76,0 -7.085,-1.56 -9.126,-3.955 -0.927,-1.088 -2.27,-1.73 -3.7,-1.727 -0.6,0.002 -1.202,0.002 -1.808,0.002 -23.852,0 -44.486,-0.863 -54.456,-2.119 0.393,-1.206 0.828,-2.393 1.301,-3.561 5.317,-0.599 13.12,-1.099 22.534,-1.445 2.406,-0.088 5.937,1.788 8.537,1.721 2.747,-0.071 4.574,-2.084 7.504,-2.13 4.671,-0.072 9.554,-0.11 14.58,-0.11 0.606,0 1.208,0 1.808,0.002 1.43,0.003 2.773,-0.639 3.7,-1.727 2.041,-2.394 5.366,-3.954 9.126,-3.954 2.753,0 5.272,0.836 7.224,2.223 3.42,2.431 7.429,3.913 11.621,4.088 4.961,0.206 9.413,0.456 13.215,0.742 0.65,1.552 1.233,3.139 1.739,4.76"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path172"
        />
        <path
          d="m 411.579,268.89 c 0,-2.838 -3.744,-5.139 -8.361,-5.139 -4.618,0 -8.362,2.301 -8.362,5.139 0,2.838 3.744,5.139 8.362,5.139 4.617,0 8.361,-2.301 8.361,-5.139"
          style={{
            fill: "#a78a81",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path174"
        />
        <path
          d="m 406.445,268.89 c 0,-1.589 -2.095,-2.877 -4.681,-2.877 -2.585,0 -4.681,1.288 -4.681,2.877 0,1.589 2.096,2.877 4.681,2.877 2.586,0 4.681,-1.288 4.681,-2.877"
          style={{
            fill: "#927571",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path176"
        />
        <path
          d="m 439.109,294.169 c -1.221,-0.132 -2.447,-0.256 -3.64,-0.201 -3.246,0.151 -6.269,1.659 -9.462,2.259 -9.711,1.824 -20.021,-4.816 -29.278,-1.361 -3.145,1.174 -5.83,3.436 -9.057,4.357 -3.341,0.953 -6.903,0.374 -10.327,-0.212 -6.246,-1.068 -12.493,-2.135 -18.741,-3.203 -0.945,-0.162 -1.959,-0.315 -2.83,0.088 -0.981,0.455 -1.529,1.497 -2.313,2.244 -1.708,1.629 -4.333,1.711 -6.691,1.602 -4.748,-0.22 -9.478,-0.828 -14.129,-1.805 -0.55,-2.748 -0.884,-5.572 -0.999,-8.453 3.382,1.098 6.844,2.618 10.025,2.638 0.824,0.004 1.779,-0.097 2.234,-0.784 0.331,-0.501 0.281,-1.151 0.395,-1.74 0.394,-2.049 2.662,-3.104 4.686,-3.61 5.457,-1.364 11.315,-1.08 16.614,0.808 5.731,2.041 11.335,5.971 17.311,4.829 4.266,-0.815 7.861,-4.159 12.199,-4.35 2.818,-0.123 5.488,1.112 8.147,2.052 4.69,1.658 9.894,2.433 14.631,0.912 3.29,-1.057 6.168,-3.156 9.455,-4.223 4.006,-1.299 8.459,-0.777 12.217,1.103 0,0.059 0.004,0.116 0.004,0.175 0,2.331 -0.165,4.623 -0.451,6.875"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path178"
        />
        <path
          d="m 427.333,321.506 c -1.903,-0.315 -3.831,-0.735 -5.679,-0.244 -1.877,0.499 -3.387,1.871 -5.15,2.687 -5.684,2.63 -12.309,-0.95 -18.545,-0.375 -1.802,0.166 -3.605,0.683 -5.393,0.41 -2.566,-0.393 -4.628,-2.333 -7.11,-3.093 -4.16,-1.275 -8.625,0.945 -12.951,0.48 -1.615,-0.174 -3.174,-0.72 -4.792,-0.865 -3.95,-0.355 -7.664,1.677 -11.25,3.37 -2.565,1.21 -5.344,2.266 -8.13,2.484 -3,-2.861 -5.671,-6.06 -7.95,-9.542 3.228,-0.302 7.84,1.024 10.387,0.426 0.889,-0.208 1.877,-0.608 2.158,-1.477 0.217,-0.672 -0.073,-1.397 -0.046,-2.102 0.075,-2.089 2.643,-3.05 4.729,-3.176 3.731,-0.225 7.515,0.512 10.888,2.122 1.771,0.845 3.486,1.943 5.435,2.173 3.792,0.448 7.406,-2.496 11.184,-1.937 1.862,0.275 3.488,1.373 5.268,1.989 5.093,1.765 10.611,-0.569 15.463,-2.918 4.852,-2.348 10.257,-4.821 15.434,-3.319 1.16,0.336 2.264,0.866 3.448,1.098 3.356,0.657 7.114,-1.073 10.339,-0.842 -1.999,4.582 -4.608,8.837 -7.737,12.651"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path180"
        />
        <path
          d="m 366.204,323.03 c 0.454,0.157 0.899,0.409 1.146,0.822 0.055,0.093 0.101,0.202 0.079,0.308 -0.027,0.129 -0.143,0.216 -0.253,0.288 -1.147,0.739 -2.615,0.957 -3.927,0.584 -0.382,-0.109 -0.775,-0.286 -0.979,-0.627 -0.961,-1.601 3.196,-1.629 3.934,-1.375"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path182"
        />
        <path
          d="m 390.619,258.714 c 0,-1.786 -2.26,-3.234 -5.046,-3.234 -2.787,0 -5.046,1.448 -5.046,3.234 0,1.786 2.259,3.234 5.046,3.234 2.786,0 5.046,-1.448 5.046,-3.234"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path184"
        />
        <path
          d="m 396.931,311.152 c 0,-0.685 -0.856,-1.239 -1.911,-1.239 -1.055,0 -1.911,0.554 -1.911,1.239 0,0.684 0.856,1.239 1.911,1.239 1.055,0 1.911,-0.555 1.911,-1.239"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path186"
        />
        <path
          d="m 412.718,245.272 c -1.058,0.596 -1.84,1.577 -2.803,2.316 -2.266,1.74 -5.549,0.994 -8.151,2.172 -6.908,3.129 -18.655,2.689 -23.974,-0.55 -0.995,-0.606 -3.108,-1.523 -3.961,-1.654 -0.851,-0.132 -1.706,0.15 -2.529,0.407 -3.542,1.108 -7.206,1.826 -10.905,2.136 -1.074,0.089 -2.22,0.13 -3.157,-0.403 -0.521,-0.297 -0.94,-0.754 -1.473,-1.03 -0.958,-0.496 -2.103,-0.334 -3.181,-0.329 -0.806,0.004 -2.331,-0.219 -3.573,-0.755 1.311,-1.208 2.679,-2.353 4.105,-3.428 1.445,0.438 2.883,1.026 4.344,1.268 2.567,0.425 5.226,0.38 7.673,-0.588 1.91,-0.756 3.623,-2.039 5.635,-2.454 2.399,-0.497 4.851,0.307 7.204,0.993 4.604,1.344 9.331,2.269 14.102,2.762 1.42,0.146 2.862,0.254 4.266,0.006 1.362,-0.242 2.64,-0.81 3.904,-1.372 1.743,-0.777 3.486,-1.553 5.23,-2.33 1.999,-0.89 4.059,-1.78 6.159,-2.428 2.314,1.278 4.524,2.722 6.615,4.315 -1.899,0.163 -3.888,0.02 -5.53,0.946"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path188"
        />
        <path
          d="m 397.921,241.843 c 0,-1.012 -1.288,-1.832 -2.878,-1.832 -1.589,0 -2.877,0.82 -2.877,1.832 0,1.011 1.288,1.831 2.877,1.831 1.59,0 2.878,-0.82 2.878,-1.831"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path190"
        />
        <path
          d="m 385.168,286.528 c 0,-1.435 -1.559,-2.598 -3.481,-2.598 -1.922,0 -3.48,1.163 -3.48,2.598 0,1.434 1.558,2.597 3.48,2.597 1.922,0 3.481,-1.163 3.481,-2.597"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path192"
        />
        <path
          d="m 407.199,296.628 c 0,-0.63 -1.372,-1.142 -3.063,-1.142 -1.692,0 -3.064,0.512 -3.064,1.142 0,0.631 1.372,1.143 3.064,1.143 1.691,0 3.063,-0.512 3.063,-1.143"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path194"
        />
        <path
          d="m 425.04,275.77 c 0,-0.857 -1.336,-1.552 -2.983,-1.552 -1.648,0 -2.983,0.695 -2.983,1.552 0,0.856 1.335,1.551 2.983,1.551 1.647,0 2.983,-0.695 2.983,-1.551"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path196"
        />
        <path
          d="m 361.689,299.055 c 0,-0.71 -1.07,-1.284 -2.389,-1.284 -1.32,0 -2.39,0.574 -2.39,1.284 0,0.709 1.07,1.283 2.39,1.283 1.319,0 2.389,-0.574 2.389,-1.283"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path198"
        />
        <path
          d="m 368.746,265.128 c 0,-0.488 -0.732,-0.884 -1.636,-0.884 -0.903,0 -1.635,0.396 -1.635,0.884 0,0.489 0.732,0.885 1.635,0.885 0.904,0 1.636,-0.396 1.636,-0.885"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path200"
        />
        <path
          d="m 407.021,329.188 c 0.492,0.424 0.944,1.01 0.867,1.655 -0.062,0.515 -0.461,0.94 -0.926,1.171 -0.464,0.231 -0.989,0.301 -1.504,0.362 -2.59,0.308 -5.34,0.452 -7.678,-0.704 -2.513,-1.242 -2.312,-4.077 0.483,-4.615 2.704,-0.521 6.657,0.318 8.758,2.131"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path202"
        />
        <path
          d="m 360.792,283.067 c 0.466,0.257 0.89,0.596 1.384,0.794 0.494,0.199 1.105,0.229 1.518,-0.108 0.435,-0.355 0.51,-0.982 0.508,-1.543 -0.002,-0.44 -0.033,-0.889 -0.201,-1.296 -0.397,-0.958 -1.445,-1.463 -2.443,-1.744 -1.225,-0.344 -10.329,-0.587 -8.971,2.271 0.407,0.858 2.545,0.83 3.298,0.884 1.655,0.116 3.395,-0.09 4.907,0.742"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path204"
        />
        <path
          d="m 421.727,262.082 c 0.691,0.057 1.381,0.114 2.072,0.171 0.476,0.039 0.977,0.075 1.411,-0.128 0.821,-0.383 1.062,-1.498 0.78,-2.359 -0.339,-1.034 -1.251,-1.813 -2.274,-2.183 -1.49,-0.54 -4.921,-0.722 -5.614,1.159 -0.761,2.066 2.091,3.214 3.625,3.34"
          style={{
            fill: "#cca899",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path206"
        />
        <path
          d="m 385.928,327.312 c 0.398,0.558 0.769,1.284 0.463,1.896 -0.21,0.418 -0.673,0.637 -1.109,0.808 -0.737,0.291 -1.51,0.538 -2.302,0.53 -2.201,-0.021 -6.924,-2.921 -4.75,-5.492 2.296,-2.714 6.239,0.215 7.698,2.258"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path208"
        />
        <path
          d="m 377.235,310.726 c 0.419,-0.256 0.857,-0.531 1.09,-0.962 0.17,-0.314 0.214,-0.68 0.234,-1.036 0.025,-0.466 0.013,-0.935 -0.034,-1.4 -0.053,-0.522 -0.151,-1.039 -0.295,-1.544 -0.103,-0.367 -0.261,-0.763 -0.606,-0.926 -0.227,-0.108 -0.491,-0.09 -0.741,-0.07 -2.61,0.208 -4.495,1.142 -4.118,4.079 0.357,2.782 2.263,3.209 4.47,1.859"
          style={{
            fill: "#e9dbc0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path210"
        />
      </g>
    </g>
  );
};

export default Jupiter;
