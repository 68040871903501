import React from "react";
import { PlanetSVGProps } from "./../types";

const Uranus: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 105.92201}, ${height / 138.14087})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-101.45933,250.19823)"
        id="g8"
      >
        <path
          d="m 155.536,135.405 c 0,-21.936 -17.784,-39.7191 -39.721,-39.7191 -21.9365,0 -39.7205,17.7831 -39.7205,39.7191 0,21.938 17.784,39.721 39.7205,39.721 21.937,0 39.721,-17.783 39.721,-39.721"
          style={{
            fill: "#44fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path218"
        />
        <path
          d="m 115.815,175.126 c -21.1591,0 -38.4478,-16.547 -39.6478,-37.405 1.2816,-1.341 2.7898,-2.475 4.3426,-3.144 3.1804,-1.371 7.2109,0.037 10.1152,-1.876 1.2645,-0.833 2.0191,-2.241 3.1172,-3.284 2.6547,-2.52 6.7928,-2.509 10.4088,-1.944 3.618,0.563 7.387,1.512 10.858,0.352 2.71,-0.905 5.022,-3.047 7.872,-3.25 1.815,-0.129 3.585,0.561 5.397,0.73 3.091,0.287 6.09,-0.942 9.077,-1.786 5.194,-1.47 10.704,-1.791 16.039,-0.978 1.381,4.036 2.142,8.36 2.142,12.864 0,21.938 -17.784,39.721 -39.721,39.721"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path220"
        />
        <path
          d="m 150.83,138.062 c 1.505,-0.203 3.1,-0.377 4.63,-0.304 -1.217,20.841 -18.498,37.368 -39.645,37.368 -12.446,0 -23.5494,-5.73 -30.8306,-14.69 0.8039,-0.88 1.8769,-1.624 3.2336,-2.16 2.4695,-0.976 5.3988,-0.038 7.7898,-1.274 2.2551,-1.166 3.0074,-3.875 5.2002,-5.078 2.473,-1.356 5.512,0.104 8.329,-0.035 6.007,-0.298 9.536,-7.556 15.421,-8.796 3.203,-0.676 6.603,0.57 9.774,-0.237 1.597,-0.406 3.015,-1.304 4.505,-2.007 3.609,-1.703 7.638,-2.255 11.593,-2.787"
          style={{
            fill: "#cffffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path222"
        />
        <path
          d="m 104.73,84.043 c -0.187,0 -0.37,0.0191 -0.55,0.0621 -2.47,0.5758 -3.765,4.8551 -3.852,12.7168 -0.002,0.239 0.189,0.4351 0.428,0.4371 0.264,0.0051 0.434,-0.1891 0.436,-0.427 0.079,-7.1 1.239,-11.432 3.184,-11.8851 4.525,-1.0578 14.172,18.2611 21.264,48.7061 3.188,13.683 5.164,26.78 5.565,36.878 0.381,9.609 -0.783,15.669 -3.114,16.212 -1.861,0.442 -4.703,-2.698 -7.774,-8.601 -0.109,-0.212 -0.372,-0.293 -0.582,-0.184 -0.212,0.109 -0.295,0.37 -0.184,0.582 3.412,6.558 6.353,9.597 8.736,9.045 3.652,-0.851 4.072,-9.762 3.781,-17.088 -0.403,-10.151 -2.387,-23.307 -5.586,-37.039 -5.758,-24.716 -15.329,-49.4139 -21.752,-49.415"
          style={{
            fill: `${foreground}`,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path224"
        />
        <path
          d="m 112.422,124.806 c 0.715,-0.041 1.442,-0.138 2.085,-0.453 0.612,-0.301 1.118,-0.789 1.519,-1.339 0.311,-0.429 0.569,-0.914 0.628,-1.442 0.1,-0.887 -0.393,-1.764 -1.084,-2.332 -0.691,-0.567 -1.557,-0.872 -2.418,-1.114 -2.552,-0.717 -7.862,-1.189 -8.066,2.685 -0.188,3.582 4.748,4.142 7.336,3.995"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path226"
        />
        <path
          d="m 87.7223,125.683 c -2.4594,-0.794 -6.9563,0.24 -4.002,3.141 0.7344,0.722 1.9238,1.455 2.9977,1.143 1.4269,-0.416 3.007,-3.638 1.0043,-4.284"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path228"
        />
        <path
          d="m 146.479,117.21 c 0.275,-1.044 -0.499,-2.064 -1.306,-2.781 -1.618,-1.441 -3.666,-2.492 -5.832,-2.558 -2.613,-0.08 -8.544,3.657 -4.406,5.868 1.588,0.849 3.644,0.777 5.383,0.812 1.712,0.035 5.517,1.109 6.161,-1.341"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path230"
        />
        <path
          d="m 102.559,132.152 c 1.105,0.717 1.7,2.284 1.046,3.426 -0.421,0.736 -1.229,1.15 -2.003,1.494 -2.1809,0.968 -5.368,0.174 -5.186,-2.764 0.1719,-2.775 4.187,-3.425 6.143,-2.156"
          style={{
            fill: "#44fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path232"
        />
        <path
          d="m 148.974,125.943 c 0.165,0.061 0.341,0.133 0.434,0.284 0.113,0.179 0.07,0.413 0.024,0.621 -0.24,1.071 -0.522,2.216 -1.342,2.947 -0.37,0.33 -0.826,0.55 -1.287,0.734 -0.935,0.374 -1.914,0.623 -2.896,0.847 -1.759,0.4 -3.958,0.796 -5.484,-0.458 -1.228,-1.01 -1.221,-2.282 -0.174,-3.406 2.517,-2.701 7.529,-2.727 10.725,-1.569"
          style={{
            fill: "#44fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path234"
        />
        <path
          d="m 113.239,145.763 c 0.541,-0.642 0.832,-1.553 0.543,-2.34 -0.105,-0.286 -0.281,-0.538 -0.5,-0.749 -0.279,-0.269 -0.631,-0.47 -1,-0.59 -0.66,-0.216 -1.373,-0.201 -2.061,-0.106 -4.946,0.677 0.152,7.182 3.018,3.785"
          style={{
            fill: "#cffffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path236"
        />
        <path
          d="m 107.866,155.493 c 0.405,0.313 0.754,0.712 0.944,1.188 0.398,0.995 0.004,2.197 -0.793,2.915 -1.765,1.59 -5.997,1.196 -6.28,-1.649 -0.309,-3.126 4.127,-4.002 6.129,-2.454"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path238"
        />
        <path
          d="m 148.081,142.059 c 0.737,0.322 1.074,1.162 1.339,1.921 0.104,0.297 0.205,0.636 0.059,0.915 -0.088,0.167 -0.251,0.279 -0.411,0.379 -1.629,1.031 -5.662,2.769 -6.371,-0.151 -0.618,-2.545 3.523,-3.88 5.384,-3.064"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path240"
        />
        <path
          d="m 95.4926,152.973 c 0.2551,-0.845 -0.193,-1.785 -0.8895,-2.327 -0.2277,-0.176 -0.4769,-0.318 -0.7398,-0.433 -0.541,-0.235 -1.1399,-0.355 -1.7293,-0.426 -7.7664,-0.94 2.0473,7.54 3.3586,3.186"
          style={{
            fill: "#cffffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path242"
        />
        <path
          d="m 122.585,132.376 c -0.45,0.259 -1.012,0.17 -1.523,0.072 l -0.384,-0.346 c -0.418,-0.499 -0.513,-1.246 -0.236,-1.834 0.278,-0.588 0.914,-0.989 1.565,-0.984 0.248,0.002 0.509,0.067 0.681,0.244 0.088,0.092 0.147,0.207 0.201,0.323 0.199,0.422 0.356,0.878 0.338,1.345 -0.018,0.467 -0.237,0.947 -0.642,1.18"
          style={{
            fill: "#44fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path244"
        />
        <path
          d="m 124.043,147.17 c 0.061,0.03 0.104,0.085 0.145,0.139 1.069,1.42 2.274,6.892 -1.23,5.145 -2.244,-1.119 -0.888,-6.267 1.085,-5.284"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path246"
        />
        <path
          d="m 104.889,100.346 c 0.957,-0.345 1.986,-0.6948 2.97,-0.439 0.984,0.256 1.796,1.401 1.322,2.3 -0.132,0.25 -0.342,0.448 -0.555,0.635 -1.724,1.512 -5.524,4.137 -7.76,2.259 -2.9164,-2.451 2.266,-4.122 4.023,-4.755"
          style={{
            fill: "#a8fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path248"
        />
        <path
          d="m 85.1117,136.849 c 0.6832,0.245 1.3488,0.546 1.9574,0.94 0.3723,0.241 -2.0843,1.662 -2.3187,1.726 -0.9004,0.242 -2.2277,0.33 -2.9137,-0.429 -0.4808,-0.531 -0.5894,-1.397 -0.1277,-1.973 0.7769,-0.969 2.4258,-0.615 3.4027,-0.264"
          style={{
            fill: "#44fffa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path250"
        />
      </g>
    </g>
  );
};

export default Uranus;
