import React from "react";
import { PlanetSVGProps } from "./../types";

const Mars: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 104.94067}, ${height / 104.93999})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-96.937992,417.98933)"
        id="g8"
      >
        <path
          d="m 151.409,274.139 c 0,-21.734 -17.619,-39.352 -39.353,-39.352 -21.7337,0 -39.3525,17.618 -39.3525,39.352 0,21.734 17.6188,39.353 39.3525,39.353 21.734,0 39.353,-17.619 39.353,-39.353"
          style={{
            fill: "#ff4953",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path104"
        />
        <path
          d="m 113.704,251.659 c 0.501,1.803 2.615,3.246 2.087,5.042 -0.441,1.495 -2.394,1.891 -3.399,3.081 -1.346,1.593 -0.627,4.022 0.321,5.88 0.363,0.711 0.762,1.463 0.688,2.257 -0.04,0.43 -0.219,0.835 -0.442,1.204 -1.737,2.873 -5.771,3.326 -9.057,2.636 -3.286,-0.689 -6.4633,-2.195 -9.8184,-2.058 -1.4863,0.061 -3.0625,0.519 -3.9777,1.692 -0.6606,0.848 -0.8856,1.945 -1.2879,2.941 -1.1926,2.955 -4.1778,5.097 -7.3578,5.282 2.0082,-0.116 -2.0895,6.968 -3.4329,7.565 -0.8984,0.399 -1.8968,0.593 -2.8945,0.594 -1.5699,-4.249 -2.4293,-8.841 -2.4293,-13.636 0,-21.734 17.6188,-39.352 39.3525,-39.352 1.925,0 3.816,0.145 5.667,0.412 1.423,2.958 1.526,6.549 -0.284,9.258 -1.564,2.342 -4.488,4.487 -3.735,7.202"
          style={{
            fill: "#dd3253",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path106"
        />
        <path
          d="m 114.756,292.026 c -1.108,1.037 -1.195,3.271 -2.702,3.447 -0.583,0.068 -1.137,-0.252 -1.603,-0.609 -1.089,-0.835 -1.957,-1.928 -3.031,-2.783 -1.073,-0.856 -2.479,-1.476 -3.805,-1.122 -1.326,0.354 -2.288,2.027 -1.541,3.178 0.49,0.754 1.58,1.207 1.567,2.106 -0.01,0.649 -0.614,1.102 -1.128,1.498 -1.526,1.178 -2.7989,2.919 -2.7462,4.846 0.052,1.927 3.2752,4.523 3.0212,5.125 1.601,-0.282 2.938,1.619 2.612,3.212 -0.141,0.696 -0.501,1.3 -0.975,1.822 -8.9988,-1.769 -16.8957,-6.602 -22.5602,-13.372 0.3657,-0.667 0.7059,-1.347 0.8989,-2.076 0.5004,-1.891 0.1566,-4.107 1.7043,-5.597 0.6961,-0.67 1.6613,-1.011 2.3433,-1.696 1.2903,-1.294 1.259,-3.464 2.4313,-4.866 1.0566,-1.263 2.8945,-1.667 3.9254,-2.951 0.5273,-0.656 0.7906,-1.48 1.1906,-2.22 1.1609,-2.148 3.5918,-3.545 6.0324,-3.467 2.44,0.079 4.776,1.629 5.796,3.848 0.947,2.06 0.979,4.785 2.893,6.001 1.237,0.788 2.838,0.615 4.277,0.333 1.44,-0.283 2.959,-0.642 4.341,-0.151 1.383,0.49 2.414,2.25 1.587,3.462 -0.953,1.396 -3.295,0.877 -4.529,2.032"
          style={{
            fill: "#dd3253",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path108"
        />
        <path
          d="m 113.897,313.445 c -0.936,-1.244 -1.733,-3.403 -1.335,-4.731 0.314,-1.05 1.455,-1.345 1.959,-2.21 0.853,-1.461 0.532,-2.191 0.508,-3.835 -0.036,-2.489 2.43,-4.242 4.748,-5.149 2.319,-0.907 4.962,-1.577 6.401,-3.608 2.108,-2.977 0.482,-7.125 -1.579,-10.135 -0.253,-0.368 -0.52,-0.756 -0.557,-1.201 -0.082,-0.975 0.966,-1.684 1.934,-1.822 2.03,-0.29 3.938,0.881 5.814,1.709 1.876,0.828 4.338,1.232 5.758,-0.248 0.925,-0.964 1.076,-2.403 1.407,-3.697 0.332,-1.294 1.118,-2.709 2.445,-2.857 1.6,-0.179 2.71,1.561 3.154,3.108 0.443,1.547 0.784,3.361 2.186,4.151 1.021,0.576 2.365,0.444 3.54,0.577 -4.06,16.64 -18.694,29.133 -36.383,29.948"
          style={{
            fill: "#dd3253",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path110"
        />
        <path
          d="m 101.191,236.318 c 0.975,0.339 1.857,0.899 2.401,1.76 0.812,1.284 0.504,3.305 -0.887,3.913 -1.215,0.532 -3.0882,0.103 -3.5507,1.345 -0.4156,1.116 0.9417,2.03 2.0537,2.455 0.345,2.856 -0.469,5.836 -2.2193,8.121 -0.2879,0.376 -0.6153,0.772 -0.6317,1.246 -0.0136,0.391 0.1895,0.753 0.2883,1.131 0.275,1.05 -0.3816,2.257 -1.4125,2.597 -1.2285,0.404 -2.548,-0.359 -3.4277,-1.308 -0.8789,-0.948 -1.5348,-2.115 -2.5617,-2.9 -2.3059,-1.762 -5.5602,-1.056 -8.4137,-0.532 -0.5875,0.108 -1.1918,0.204 -1.7789,0.095 -0.8738,-0.162 -1.6094,-0.623 -2.1778,-1.244 5.0997,-7.987 12.9985,-14.006 22.318,-16.679"
          style={{
            fill: "#c52653",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path112"
        />
        <path
          d="m 145.492,294.326 c 0.087,0.043 0.159,0.104 0.239,0.155 -4.843,8.003 -12.453,14.138 -21.508,17.081 -0.067,-0.171 -0.116,-0.352 -0.137,-0.545 -0.128,-1.215 1.143,-1.982 1.232,-3.158 0.093,-1.227 -1.268,-2.495 -0.619,-3.691 0.258,-0.473 0.784,-0.723 1.276,-0.943 2.324,-1.036 4.797,-2.096 7.321,-1.769 0.309,-1.132 0.62,-2.264 0.93,-3.396 -2.527,-1.372 -3.972,-4.488 -3.387,-7.303 0.912,-0.872 2.385,0.023 3.261,0.931 0.877,0.909 2.197,1.929 3.243,1.223 0.45,-0.303 0.697,-0.871 1.191,-1.095 1.093,-0.494 1.981,0.966 3.004,1.592 1.163,0.711 2.731,0.314 3.954,0.918"
          style={{
            fill: "#c52653",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path114"
        />
        <path
          d="m 149.334,261.535 c -0.832,0.505 -1.674,0.985 -2.128,1.823 -0.541,0.997 -0.364,2.208 -0.378,3.343 -0.014,1.134 -0.39,2.457 -1.459,2.839 -1.236,0.441 -2.438,-0.639 -3.39,-1.541 -0.952,-0.904 -2.475,-1.729 -3.469,-0.873 -0.59,0.51 -0.664,1.381 -0.723,2.159 -0.099,1.281 -0.301,2.625 -1.088,3.641 -0.788,1.017 -2.338,1.547 -3.403,0.826 -1.537,-1.043 -0.873,-3.478 0.256,-4.953 1.13,-1.475 2.66,-3.013 2.38,-4.85 -0.261,-1.714 -1.978,-2.745 -3.478,-3.615 -1.501,-0.869 -3.14,-2.138 -3.061,-3.871 0.03,-0.678 0.334,-1.316 0.414,-1.99 0.45,-3.779 -5.8,-5.813 -5.339,-9.591 0.263,-2.153 2.469,-3.371 3.363,-5.221 0.176,-0.364 0.318,-0.846 0.463,-1.365 9.859,4.474 17.541,12.889 21.04,23.239"
          style={{
            fill: "#dd3253",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path116"
        />
        <path
          d="m 118.033,271.583 c 0.488,-0.635 0.901,-1.453 0.648,-2.213 -0.198,-0.595 -0.758,-1.001 -1.014,-1.573 -0.521,-1.159 0.367,-2.466 1.353,-3.267 0.291,-0.238 0.601,-0.463 0.821,-0.767 0.714,-0.986 0.264,-2.345 0.248,-3.562 -0.002,-0.155 0.01,-0.326 0.118,-0.436 0.108,-0.111 0.277,-0.127 0.431,-0.133 0.849,-0.031 1.728,0.069 2.47,0.482 0.743,0.413 1.323,1.187 1.327,2.037 0.003,0.794 -0.477,1.54 -0.431,2.333 0.062,1.064 1.016,1.821 1.863,2.469 0.273,0.208 0.553,0.433 0.692,0.748 0.277,0.629 -0.103,1.331 -0.3,1.989 -0.436,1.466 2.18,1.091 1.388,4.071 -0.063,0.236 -3.392,0.793 -3.801,0.756 -1.685,-0.155 -2.37,-0.326 -3.909,0.557 -3.23,1.851 -3.755,-1.08 -1.904,-3.491"
          style={{
            fill: "#dd3253",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path118"
        />
        <path
          d="m 146.157,254.526 c -0.01,0.015 -0.017,0.032 -0.027,0.046 -0.795,1.168 -2.554,1.714 -3.692,0.876 -0.74,0.346 -0.513,1.425 -0.391,2.233 0.129,0.849 -0.04,1.803 -0.679,2.377 -0.639,0.573 -1.808,0.548 -2.244,-0.191 -0.222,-0.376 -0.221,-0.838 -0.214,-1.274 0.027,-1.693 0.053,-3.386 0.081,-5.08 0.009,-0.598 0.01,-1.23 -0.288,-1.748 -0.553,-0.96 -1.84,-1.122 -2.941,-1.243 -1.102,-0.119 -2.406,-0.476 -2.724,-1.537 1.255,-0.709 1.606,-2.622 0.683,-3.729 -0.266,-0.32 -0.617,-0.577 -0.81,-0.946 -0.507,-0.966 0.283,-2.072 1.143,-2.78 4.956,3.351 9.106,7.796 12.103,12.996"
          style={{
            fill: "#c52653",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path120"
        />
      </g>
    </g>
  );
};

export default Mars;
