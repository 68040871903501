import React from "react";
import { PlanetSVGProps } from "./../types";

const Neptune: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 113.80533}, ${height / 113.80411})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-276.43066,232.36666)"
        id="g8"
      >
        <path
          d="m 292.677,131.599 c 0,-23.57 -19.108,-42.6771 -42.677,-42.6771 -23.57,0 -42.677,19.1071 -42.677,42.6771 0,23.57 19.107,42.676 42.677,42.676 23.569,0 42.677,-19.106 42.677,-42.676"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path258"
        />
        <path
          d="m 292.677,131.599 c 0,23.57 -19.108,42.676 -42.677,42.676 -23.57,0 -42.677,-19.106 -42.677,-42.676 0,-1.687 0.109,-3.347 0.299,-4.983 3.791,-4.479 7.513,-9.68 12.665,-11.165 2.793,-0.783 5.854,-0.292 8.586,-1.268 3.376,-1.206 5.64,-4.417 8.817,-6.079 6.34,-3.317 14.285,0.292 21.169,-1.654 2.384,-0.674 4.534,-1.99 6.857,-2.849 3.161,-1.171 6.567,-1.472 9.926,-1.763 1.514,-0.131 3.052,-0.253 4.598,-0.351 7.685,7.719 12.437,18.36 12.437,30.112"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path260"
        />
        <path
          d="m 250,174.275 c -18.201,0 -33.731,-11.398 -39.866,-27.442 3.468,-4.356 7.974,-6.373 11.804,-10.199 2.824,-2.822 2.511,-7.742 5.278,-10.62 2.909,-3.027 7.89,-2.558 11.589,-4.542 1.274,-0.684 2.383,-1.656 3.692,-2.27 2.69,-1.262 5.815,-0.863 8.786,-0.907 9.159,-0.138 17.575,-4.668 26.173,-7.827 2.62,-0.962 5.395,-1.781 8.198,-2.313 4.435,6.729 7.023,14.782 7.023,23.444 0,23.57 -19.108,42.676 -42.677,42.676"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path262"
        />
        <path
          d="m 250,174.275 c -8.734,0 -16.85,-2.629 -23.611,-7.133 0.598,-4.181 0.282,-9.09 3.257,-11.264 2.115,-1.545 4.176,-1.309 5.791,-3.758 1.508,-2.287 1.472,-5.549 2.191,-8.121 1.394,-4.985 5.331,-8.938 9.805,-11.543 4.473,-2.605 9.506,-4.053 14.447,-5.598 2.48,-0.775 4.974,-1.591 7.207,-2.921 1.666,-0.993 3.16,-2.257 4.838,-3.231 4.886,-2.835 10.806,-2.96 16.475,-2.869 1.474,4.317 2.277,8.945 2.277,13.762 0,23.57 -19.108,42.676 -42.677,42.676"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path264"
        />
        <path
          d="m 250,174.275 c -3.153,0 -6.223,-0.347 -9.18,-0.997 -1.019,-1.912 -1.039,-4.178 0.254,-6.62 3.005,-5.677 7.312,-5.348 7.89,-12.662 0.271,-3.427 2.13,-6.694 4.937,-8.679 1.366,-0.964 2.977,-1.676 3.963,-3.027 0.783,-1.071 1.076,-2.428 1.783,-3.551 2.749,-4.363 9.538,-3.024 14.033,-5.55 1.438,-0.807 2.616,-2.009 3.997,-2.913 4.508,-2.95 10.673,-2.02 14.998,1.246 0,0.027 0.002,0.051 0.002,0.077 0,23.57 -19.108,42.676 -42.677,42.676"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path266"
        />
        <path
          d="m 269.295,163.357 c -0.48,-0.864 -1.149,-1.633 -2.079,-1.966 -0.931,-0.332 -1.945,-0.345 -2.93,-0.268 -0.416,0.033 -0.85,0.089 -1.196,0.324 -0.344,0.234 -0.554,0.615 -0.738,0.987 -0.483,0.975 -0.879,1.999 -1.467,2.915 -0.957,1.492 -1.963,1.712 -3.497,2.265 -0.91,0.328 -3.176,1.031 -1.693,2.084 4.588,3.262 15.042,-3.736 13.6,-6.341"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path268"
        />
        <path
          d="m 268.679,153.525 c 0.339,-0.144 0.707,-0.281 1.068,-0.206 0.308,0.066 0.563,0.276 0.803,0.479 0.461,0.39 0.928,0.788 1.261,1.293 0.333,0.505 0.516,1.14 0.351,1.721 -0.475,1.669 -3.543,2.197 -4.687,0.971 -1.347,-1.442 -0.354,-3.589 1.204,-4.258"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path270"
        />
        <path
          d="m 231.08,97.4711 c 2.342,-1.8789 4.721,-3.7813 7.478,-4.9699 4.884,-2.1051 10.455,-1.7723 15.713,-0.9801 3.437,0.5187 6.847,1.216 10.21,2.091 0.458,0.1188 0.971,0.2848 1.172,0.7141 0.211,0.4476 -0.025,0.9879 -0.363,1.3519 -2.001,2.1551 -5.866,-0.016 -8.46,1.3699 -0.804,0.4309 -1.4,1.168 -2.167,1.6629 -1.177,0.759 -2.65,0.8813 -4.05,0.8473 -3.28,-0.0801 -6.502,-0.891 -9.775,-1.1141 -3.274,-0.2211 -6.795,0.2399 -9.309,2.3469 -1.413,1.185 -4.021,5.941 -6.19,3.521 -2.071,-2.313 4.16,-5.5729 5.741,-6.8409"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path272"
        />
        <path
          d="m 231.255,143.607 c 0.47,-2.274 2.173,-4.253 4.352,-5.057 0.369,-0.862 -0.086,-1.92 -0.86,-2.448 -0.774,-0.529 -1.78,-0.608 -2.706,-0.465 -1.009,0.155 -2.016,0.584 -2.655,1.381 -0.937,1.169 -1.045,3.035 -2.374,3.727 -0.363,0.19 -0.774,0.259 -1.165,0.381 -3.893,1.216 -3.99,6.555 -0.804,8.63 1.253,0.815 2.759,0.944 4.041,0.117 2.174,-1.403 1.72,-4.08 2.171,-6.266"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path274"
        />
        <path
          d="m 289.119,133.023 c 0.505,0.526 0.919,1.161 1.078,1.872 0.16,0.71 0.041,1.503 -0.416,2.071 -0.444,0.551 -1.158,0.837 -1.864,0.882 -2.155,0.139 -7.027,-1.914 -6.641,-4.72 0.479,-3.465 6.344,-1.668 7.843,-0.105"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path276"
        />
        <path
          d="m 271.27,143.12 c -0.25,0.644 -0.792,1.132 -1.377,1.496 -1.03,0.64 -2.251,0.965 -3.462,0.921 -0.774,-0.028 -1.644,-0.281 -1.976,-0.979 l -0.206,-0.191 c -0.656,-1.605 -0.047,-3.63 1.386,-4.608 1.432,-0.978 3.54,-0.807 4.796,0.389 0.791,0.753 1.234,1.955 0.839,2.972"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path278"
        />
        <path
          d="m 244.615,120.51 c 0.921,-0.722 2.168,-0.88 3.337,-0.812 0.606,0.035 1.3,0.184 1.583,0.721 0.275,0.519 0.034,1.15 -0.208,1.686 -0.264,0.585 -0.554,1.202 -1.089,1.555 -0.572,0.378 -1.306,0.378 -1.992,0.365 -0.877,-0.016 -2.782,10e-4 -3.172,-1.055 -0.306,-0.83 0.948,-1.996 1.541,-2.46"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path280"
        />
        <path
          d="m 228.832,111.517 c 0.284,-0.134 0.501,-0.377 0.681,-0.635 0.369,-0.53 0.608,-1.235 0.331,-1.818 -0.295,-0.62 -1.043,-0.862 -1.719,-0.98 -1.234,-0.217 -3.64,-0.035 -3.272,1.808 0.298,1.498 2.733,2.211 3.979,1.625"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path282"
        />
        <path
          d="m 231.86,127.535 c 1,-0.112 2.044,0.345 2.64,1.155 0.111,0.151 0.208,0.317 0.24,0.5 0.057,0.33 -0.103,0.655 -0.268,0.946 -0.274,0.482 -0.575,0.949 -0.9,1.398 -0.945,1.305 -3.963,1.716 -4.679,-0.071 -0.702,-1.749 1.34,-3.744 2.967,-3.928"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path284"
        />
        <path
          d="m 252.894,151.138 c 0.434,-0.986 1.29,-1.828 2.346,-2.038 0.196,-0.038 0.406,-0.054 0.587,0.031 0.141,0.067 0.248,0.188 0.346,0.31 0.84,1.038 1.376,2.321 1.525,3.648 0.201,1.798 -1.677,4.181 -3.614,3.406 -2.01,-0.804 -1.911,-3.718 -1.19,-5.357"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path286"
        />
        <path
          d="m 261.61,102.487 c -0.462,0.235 -0.993,0.292 -1.51,0.3 -0.677,0.011 -1.356,-0.057 -2.018,-0.202 l -0.6,-0.125 c -0.198,-1.092 -0.346,-2.37 0.421,-3.1721 0.482,-0.5027 1.241,-0.6789 1.928,-0.5617 0.687,0.116 1.307,0.4929 1.828,0.9547 0.474,0.4191 0.906,0.9881 0.847,1.6181 -0.05,0.515 -0.434,0.955 -0.896,1.188"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path288"
        />
        <path
          d="m 284.695,112.385 c 0.319,0.237 0.612,0.609 0.519,0.995 -0.031,0.13 -0.105,0.245 -0.186,0.35 -0.827,1.07 -5.441,2.297 -5.529,0.166 -0.104,-2.518 3.815,-2.541 5.196,-1.511"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path290"
        />
        <path
          d="m 266.912,121.69 c 0.155,-0.524 -0.059,-1.111 -0.449,-1.496 -0.389,-0.383 -0.926,-0.588 -1.465,-0.678 -1.366,-0.231 -2.752,0.203 -4.05,0.689 -1.788,0.67 -8.507,3.406 -3.561,4.779 2.651,0.736 8.591,-0.132 9.525,-3.294"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path292"
        />
        <path
          d="m 241.349,103.341 c -0.066,0.18 -0.138,0.369 -0.287,0.488 -0.155,0.123 -0.362,0.149 -0.558,0.17 -0.525,0.057 -1.051,0.113 -1.576,0.17 l -1.052,0.237 c -0.495,-0.114 -1.008,0.342 -1.489,0.177 -0.495,-0.169 -0.565,-0.831 -0.536,-1.354 0.026,-0.468 0.077,-0.976 0.395,-1.322 0.183,-0.2 0.437,-0.321 0.688,-0.426 0.989,-0.412 2.053,-0.643 3.125,-0.679 0.39,-0.014 0.809,0.008 1.123,0.24 0.665,0.492 0.45,1.521 0.167,2.299"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path294"
        />
        <path
          d="m 248.748,135.535 c 0.828,-0.291 1.789,-0.284 2.547,0.158 0.213,0.125 0.416,0.293 0.503,0.525 0.054,0.143 0.058,0.301 0.062,0.454 0.029,1.062 0.035,2.204 -0.564,3.082 -0.312,0.456 -0.763,0.799 -1.109,1.229 -0.923,1.15 -1.897,2.107 -3.53,2.077 -1.022,-0.019 -1.667,-0.594 -1.892,-1.585 -0.538,-2.359 1.894,-5.207 3.983,-5.94"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path296"
        />
        <path
          d="m 243.434,109.765 c 0.415,-0.156 0.847,-0.314 1.287,-0.27 0.82,0.083 1.457,0.982 1.261,1.782 -0.172,0.707 -0.832,1.171 -1.454,1.55 -1.142,0.694 -3.688,1.547 -4.118,-0.385 -0.404,-1.81 1.788,-2.213 3.024,-2.677"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path298"
        />
        <path
          d="m 219.997,119.264 c 1.718,0.554 0.508,5.762 -1.706,4.729 -2.328,-1.087 -0.396,-5.401 1.706,-4.729"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path300"
        />
        <path
          d="m 243.341,156.519 c 0.108,-0.405 0.17,-0.853 -0.017,-1.226 -0.156,-0.31 -0.467,-0.521 -0.801,-0.613 -0.054,-0.015 -0.108,-0.028 -0.163,-0.036 -0.592,-0.101 -1.217,0.122 -1.703,0.486 -2.782,2.09 2.01,3.912 2.684,1.389"
          style={{
            fill: "#1071ff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path302"
        />
        <path
          d="m 222.488,152.381 c 1.969,0.946 1.484,3.984 -0.84,3.143 -0.821,-0.296 -1.879,-1.349 -1.582,-2.272 0.296,-0.92 1.628,-1.252 2.422,-0.871"
          style={{
            fill: "#1056e5",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path304"
        />
        <path
          d="m 277.414,144.479 c 0.431,-0.594 1.104,-1.103 1.834,-1.03 0.529,0.054 0.989,0.406 1.312,0.829 0.705,0.924 0.854,2.151 0.876,3.312 0.024,1.247 -0.072,2.497 -0.285,3.726 -0.299,1.727 -0.901,3.521 -2.277,4.607 -1.177,0.93 -2.677,0.816 -3.462,-0.541 -0.522,-0.902 -0.204,-1.649 0.168,-2.536 1.117,-2.668 0.088,-5.955 1.834,-8.367"
          style={{
            fill: "#108aff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path306"
        />
      </g>
    </g>
  );
};

export default Neptune;
