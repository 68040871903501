import React from "react";
import { PlanetSVGProps } from "./../types";

const Venus: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 97.087982}, ${height / 97.087997})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-284.78934,605.33465)"
        id="g8"
      >
        <path
          d="m 286.408,417.593 c 0,-20.108 -16.301,-36.408 -36.408,-36.408 -20.107,0 -36.408,16.3 -36.408,36.408 0,20.108 16.301,36.408 36.408,36.408 20.107,0 36.408,-16.3 36.408,-36.408"
          style={{
            fill: "#ffb04f",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path46"
        />
        <path
          d="m 286.408,417.593 c 0,-20.108 -16.301,-36.408 -36.408,-36.408 -20.107,0 -36.408,16.3 -36.408,36.408 0,20.108 16.301,36.408 36.408,36.408 20.107,0 36.408,-16.3 36.408,-36.408"
          style={{
            fill: "#ffb04f",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path48"
        />
        <path
          d="m 276.593,441.602 c 0.081,0.262 0.095,0.523 0.067,0.775 -0.756,0.813 -1.547,1.592 -2.373,2.332 -0.254,0.063 -0.513,0.098 -0.769,0.094 -1.726,-0.025 -3.248,-1.056 -4.739,-1.924 -2.917,-1.696 -6.126,-2.89 -9.442,-3.512 -1.149,-0.215 -2.327,-0.369 -3.389,-0.856 -1.521,-0.699 -2.675,-2.027 -4.161,-2.8 -1.892,-0.984 -4.278,-0.95 -6.143,0.086 -0.337,0.188 -0.681,0.436 -0.787,0.807 -0.092,0.324 0.017,0.666 0.095,0.994 0.824,3.484 -2.01,6.995 -1.417,10.526 0.187,1.118 0.711,2.155 0.944,3.263 0.151,0.715 0.146,1.511 -0.109,2.179 -0.445,-0.069 -0.886,-0.152 -1.326,-0.236 -1.514,-3.265 -1.148,-7.218 -0.925,-10.891 0.249,-4.131 -0.007,-8.825 -3.11,-11.564 -1.973,-1.743 -5.179,-2.999 -5.035,-5.627 0.055,-1.015 0.663,-1.926 0.797,-2.934 0.336,-2.521 -2.402,-4.479 -4.937,-4.708 -2.454,-0.22 -4.984,0.61 -6.831,2.242 -1.573,1.391 -2.621,3.285 -4.118,4.758 -1.115,1.098 -2.676,1.951 -4.181,1.937 -0.604,-2.391 -0.969,-4.877 -1.074,-7.43 1.581,-0.082 3.148,-0.444 4.591,-1.096 3.824,-1.728 6.969,-5.38 11.164,-5.483 2.842,-0.071 5.406,1.544 8.046,2.597 2.64,1.054 6.053,1.403 7.975,-0.692 0.763,-0.833 1.165,-1.935 1.86,-2.825 0.895,-1.145 2.258,-1.918 2.921,-3.211 0.951,-1.855 0.092,-4.226 -1.438,-5.641 -1.531,-1.416 -3.581,-2.098 -5.564,-2.741 -4.997,-1.621 -10.034,-3.25 -15.252,-3.849 -1.443,-0.166 -3.236,-0.521 -3.597,-1.928 -0.466,-1.82 2.205,-2.991 3.931,-2.251 1.726,0.74 2.855,2.424 4.439,3.432 4.034,2.57 9.68,0.15 13.992,2.221 1.014,0.487 1.899,1.2 2.773,1.907 2.582,2.089 5.164,4.177 7.746,6.266 1.564,1.265 3.303,3.027 2.76,4.965 -0.389,1.387 -1.803,2.181 -3.084,2.839 -3.708,1.904 -7.386,3.926 -10.636,6.537 -1.954,1.57 -3.896,3.754 -3.555,6.238 0.355,2.583 2.959,4.146 5.281,5.331 3.025,1.544 6.11,3.107 9.457,3.675 2.5,0.425 5.13,0.292 7.48,1.244 1.991,0.807 3.68,2.359 5.786,2.782 2.182,0.437 5.231,0.045 5.887,2.172"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path50"
        />
        <path
          d="m 216.768,432.461 c 0.454,-0.028 0.917,-0.004 1.358,0.055 2.769,0.366 5.687,1.574 8.226,0.41 0.712,-0.326 1.343,-0.827 2.086,-1.075 0.743,-0.249 1.694,-0.161 2.13,0.49 0.402,0.6 0.213,1.399 0.296,2.117 0.176,1.518 1.548,2.574 2.313,3.897 0.724,1.253 0.959,2.847 2.076,3.766 0.422,0.347 0.939,0.566 1.359,0.915 1.471,1.219 1.294,3.485 0.986,5.37 -0.179,1.095 -0.311,2.317 -0.105,3.389 -9.244,-3.381 -16.725,-10.408 -20.725,-19.334"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path52"
        />
        <path
          d="m 251.997,453.944 c -0.101,-0.409 -0.176,-0.828 -0.31,-1.162 -0.498,-1.246 -1.385,-2.025 -2.112,-3.122 -1.037,-1.565 -1.027,-3.576 -0.98,-5.453 0.022,-0.857 0.108,-1.843 0.806,-2.341 0.928,-0.661 2.211,0.011 3.047,0.783 0.837,0.771 1.742,1.726 2.877,1.633 0.653,-0.054 1.215,-0.456 1.803,-0.743 1.864,-0.914 4.249,-0.641 5.859,0.67 0.449,0.367 0.844,0.807 1.342,1.105 1.274,0.76 2.879,0.434 4.353,0.279 1.243,-0.13 2.695,0.003 3.546,0.825 -5.658,4.37 -12.632,7.115 -20.231,7.526"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path54"
        />
        <path
          d="m 277.644,402.022 c -0.889,0.905 -2.062,1.46 -3.147,2.116 -0.506,0.305 -1.019,0.662 -1.271,1.196 -0.246,0.52 -0.203,1.138 -0.022,1.684 0.925,2.79 5.022,4.105 4.906,7.042 -0.041,1.028 -0.637,1.945 -1.265,2.762 -0.968,1.258 -2.06,2.42 -3.255,3.465 -1.385,1.212 -3.087,2.855 -2.385,4.556 2.303,0.755 4.605,1.511 6.906,2.266 0.82,0.268 1.717,0.537 2.525,0.235 1.106,-0.414 1.567,-1.683 2.145,-2.713 0.905,-1.614 2.351,-2.877 3.525,-4.302 -0.473,6.356 -2.582,12.255 -5.902,17.286 -1.402,-0.515 -2.127,-2.142 -3.607,-2.587 -1.607,-0.485 -3.286,0.365 -4.913,-0.178 -0.639,-0.264 -1.145,-0.766 -1.697,-1.182 -1.746,-1.319 -3.993,-1.804 -6.178,-1.893 -2.186,-0.089 -4.37,0.182 -6.558,0.21 -1.429,0.017 -2.929,-0.091 -4.138,-0.852 -1.209,-0.762 -1.996,-2.362 -1.41,-3.666 0.906,-2.017 4.22,-2.042 5.006,-4.109 0.377,-0.99 0.027,-2.185 0.554,-3.104 0.261,-0.454 0.7,-0.772 1.127,-1.076 1.162,-0.826 2.324,-1.653 3.486,-2.479 0.286,-0.204 0.588,-0.428 0.707,-0.757 0.083,-0.229 0.067,-0.481 0.049,-0.724 -0.116,-1.603 -0.233,-3.206 -0.35,-4.808 -0.047,-0.653 -0.098,-1.321 -0.363,-1.919 -0.258,-0.585 -0.701,-1.064 -1.077,-1.581 -0.818,-1.127 -1.33,-2.582 -0.953,-3.922 0.616,-2.192 3.23,-3.129 5.505,-3.216 1.277,-0.048 2.72,0.002 3.623,-0.902 0.932,-0.935 0.831,-2.508 0.283,-3.708 -0.549,-1.202 -1.455,-2.209 -2.029,-3.397 -0.575,-1.189 -0.748,-2.74 0.121,-3.734 0.459,-0.525 1.129,-0.806 1.778,-1.062 0.065,-0.025 0.129,-0.049 0.193,-0.074 3.16,2.018 5.987,4.509 8.382,7.373 -1.53,2.126 2.115,5.293 -0.301,7.754"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path56"
        />
        <path
          d="m 228.501,405.179 c -0.181,0.107 -0.41,0.042 -0.614,-0.011 -1.365,-0.355 -2.835,-0.022 -4.09,0.623 -2.312,1.189 -3.501,3.051 -5.223,4.773 -1.083,1.084 -1.959,1.194 -3.212,0.179 -0.376,-0.304 -0.677,-0.624 -0.923,-0.955 0.929,-4.247 2.599,-8.215 4.867,-11.765 0.365,0.134 0.68,0.37 0.874,0.715 0.187,0.334 0.246,0.724 0.405,1.073 0.534,1.165 2.011,1.55 3.293,1.522 1.282,-0.027 2.6,-0.317 3.822,0.069 0.361,0.114 0.713,0.292 0.959,0.578 0.624,0.725 0.387,1.823 0.115,2.739 -0.052,0.176 -0.116,0.367 -0.273,0.46"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path58"
        />
        <path
          d="m 250,381.185 c 4.096,0 8.028,0.687 11.702,1.933 -0.08,0.432 -0.174,0.861 -0.29,1.284 -0.149,0.544 -0.351,1.114 -0.796,1.461 -0.583,0.454 -1.395,0.395 -2.132,0.458 -1.19,0.101 -2.462,0.71 -2.831,1.846 -0.368,1.137 0.777,2.599 1.886,2.157 0.69,0.693 -0.141,1.85 -1.012,2.294 -0.872,0.444 -1.995,0.894 -2.078,1.869 -0.034,0.39 0.12,0.815 -0.065,1.16 -0.333,0.619 -1.357,0.282 -1.678,-0.343 -0.322,-0.624 -0.278,-1.382 -0.567,-2.022 -1.007,-2.225 -4.9,-1.439 -6.144,-3.541 -0.302,-0.508 -0.393,-1.123 -0.721,-1.615 -0.464,-0.696 -1.306,-1.02 -2.093,-1.302 -1.58,-0.567 -3.467,-1.098 -4.827,-0.115 -0.38,0.274 -0.687,0.651 -1.105,0.862 -1.719,0.87 -3.172,-0.774 -3.587,-2.512 4.915,-2.473 10.461,-3.874 16.338,-3.874"
          style={{
            fill: "#ff9b3d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path60"
        />
      </g>
    </g>
  );
};

export default Venus;
