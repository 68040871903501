import React from "react";
import { PlanetSVGProps } from "./../types";

const Pluto: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 86.633362}, ${height / 86.633057})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-470.78131,214.64665)"
        id="g8"
      >
        <path
          d="m 418.061,128.497 c 0,-17.941 -14.545,-32.4868 -32.488,-32.4868 -17.942,0 -32.487,14.5458 -32.487,32.4868 0,17.942 14.545,32.488 32.487,32.488 17.943,0 32.488,-14.546 32.488,-32.488"
          style={{
            fill: "#caa189",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path314"
        />
        <path
          d="m 410.439,139.28 c 0.818,0.038 1.697,0.157 2.291,0.72 0.437,0.412 0.651,1.002 0.851,1.568 0.243,0.691 0.482,1.398 0.711,2.117 -5.451,10.288 -16.264,17.3 -28.719,17.3 -17.942,0 -32.487,-14.546 -32.487,-32.487 0,-12.023 6.536,-22.514 16.244,-28.132 0.432,2.72 -0.454,6.365 -1.223,9.56 -1.12,4.66 -1.743,9.439 -1.855,14.23 -0.066,2.831 0.151,5.94 2.086,8.007 2.507,2.68 6.77,2.512 10.077,4.105 2.952,1.421 4.993,4.174 7.426,6.371 2.432,2.195 5.883,3.926 8.915,2.683 1.196,-0.49 2.169,-1.388 3.2,-2.17 3.543,-2.684 8.042,-4.081 12.483,-3.872"
          style={{
            fill: "#be8d89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path316"
        />
        <path
          d="m 389.952,141.519 c -0.482,-0.566 -0.915,-1.193 -1.123,-1.907 -0.208,-0.713 -0.167,-1.525 0.242,-2.145 0.236,-0.358 0.58,-0.633 0.955,-0.84 0.988,-0.544 2.194,-0.621 3.285,-0.334 1.882,0.496 4.766,2.444 3.205,4.613 -1.358,1.887 -5.025,2.417 -6.564,0.613"
          style={{
            fill: "#be8d89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path318"
        />
        <path
          d="m 370.258,126.33 c 0.023,-0.506 0.095,-1.045 0.424,-1.429 0.226,-0.264 0.549,-0.415 0.895,-0.462 0.312,-0.043 0.641,-0.002 0.932,0.116 0.614,0.246 1.081,0.766 1.435,1.325 1.765,2.782 -3.87,4.581 -3.686,0.45"
          style={{
            fill: "#be8d89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path320"
        />
        <path
          d="m 353.086,128.498 c 0,-2.779 0.363,-5.47 1.019,-8.044 0.879,0.806 1.433,2.291 1.895,3.444 2.1,5.239 2.145,11.077 4.494,16.233 0.296,0.651 0.637,1.298 1.145,1.802 1.643,1.629 4.32,1.245 6.595,1.668 3.666,0.681 6.446,3.583 9.039,6.261 2.593,2.68 5.597,5.444 9.317,5.707 1.163,0.083 2.335,-0.09 3.494,0.027 1.16,0.117 2.38,0.604 2.94,1.626 0.504,0.916 0.324,2.05 -0.241,2.948 -2.32,0.526 -4.73,0.815 -7.21,0.815 -17.942,0 -32.487,-14.546 -32.487,-32.487"
          style={{
            fill: "#b77f89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path322"
        />
        <path
          d="m 365.499,138.156 c -0.451,-0.972 -1.418,-1.483 -2.158,-1.139 -0.741,0.342 -0.977,1.41 -0.526,2.382 0.452,0.974 1.417,1.484 2.159,1.141 0.741,-0.344 0.975,-1.411 0.525,-2.384"
          style={{
            fill: "#b77f89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path324"
        />
        <path
          d="m 415.035,114.808 c 0.201,2.629 -0.223,5.314 -2.824,6.865 -0.995,0.594 -2.159,0.87 -3.318,0.846 -2.148,-0.045 -4.02,-0.907 -5.643,-2.15 -1.379,-1.056 -3.359,-0.926 -4.582,0.307 -0.97,0.976 -1.952,1.587 -2.344,1.751 -3.317,1.39 -7.491,-0.324 -9.096,-3.545 -3.792,-7.611 1.092,-18.286 3.74,-22.4179 10.702,1.7898 19.629,8.8069 24.067,18.3439"
          style={{
            fill: "#d4b29d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path326"
        />
        <path
          d="m 414.229,113.205 c -0.19,1.62 -0.864,3.108 -2.574,4.061 -0.896,0.499 -1.933,0.713 -2.959,0.666 -2.089,-0.097 -3.876,-1.075 -5.393,-2.399 -0.848,-0.739 -2.138,-0.63 -2.86,0.232 -0.988,1.18 -2.149,1.919 -2.561,2.091 -2.904,1.217 -6.555,-0.283 -7.959,-3.1 -2.909,-5.838 0.015,-13.737 2.343,-18.0322 9.52,1.9961 17.505,8.1452 21.963,16.4812"
          style={{
            fill: "#e0bfaa",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path328"
        />
        <path
          d="m 384.568,153.754 c -0.205,-0.078 -0.413,-0.162 -0.577,-0.308 -0.277,-0.245 -0.388,-0.622 -0.485,-0.978 -0.089,-0.322 -0.14,-0.748 0.148,-0.916 0.088,-0.052 0.192,-0.067 0.293,-0.074 1.099,-0.086 2.242,0.572 2.741,1.552 0.887,1.742 -1.252,1.052 -2.12,0.724"
          style={{
            fill: "#b77f89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path330"
        />
        <path
          d="m 382.226,103.916 c -0.116,-1.288 -1.04,-2.63 -2.333,-2.669 -0.033,-0.001 -0.065,-0.001 -0.096,0 -1.036,0.021 -1.921,0.891 -2.276,1.874 -1.626,4.509 5.058,4.772 4.705,0.795"
          style={{
            fill: "#d4b29d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path332"
        />
        <path
          d="m 364.278,108.825 c -0.306,-0.179 -0.663,-0.202 -1.005,-0.116 -0.391,0.1 -0.763,0.343 -1.017,0.66 -2.603,3.259 5.27,1.358 2.022,-0.544"
          style={{
            fill: "#caa189",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path334"
        />
        <path
          d="m 380.466,140.218 c 0.209,0.199 0.432,0.435 0.425,0.723 -0.007,0.249 -0.19,0.459 -0.392,0.604 -0.531,0.38 -1.239,0.444 -1.88,0.311 -0.64,-0.132 -1.227,-0.442 -1.797,-0.763 -0.895,-0.505 -3.046,-2.513 -0.968,-3.127 1.726,-0.51 3.507,1.19 4.612,2.252"
          style={{
            fill: "#caa189",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path336"
        />
        <path
          d="m 410.27,141.453 c 0.823,0.244 1.562,0.918 1.671,1.769 0.034,0.255 0.006,0.528 -0.137,0.741 -0.216,0.324 -0.635,0.435 -1.021,0.496 -0.718,0.113 -1.45,0.135 -2.174,0.065 -0.996,-0.096 -2.885,-0.77 -2.208,-2.124 0.577,-1.151 2.815,-1.259 3.869,-0.947"
          style={{
            fill: "#caa189",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path338"
        />
        <path
          d="m 357.781,124.096 c -0.13,-0.391 -0.236,-0.83 -0.057,-1.2 0.088,-0.183 0.239,-0.329 0.396,-0.459 0.252,-0.208 0.559,-0.396 0.885,-0.363 0.17,0.017 0.328,0.093 0.475,0.18 1.072,0.631 1.674,1.825 2.087,2.998 0.39,1.107 0.293,3.028 -1.371,2.484 -1.328,-0.432 -2.022,-2.458 -2.415,-3.64"
          style={{
            fill: "#b77f89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path340"
        />
        <path
          d="m 383.887,133.104 c 0.289,-0.302 0.472,-0.736 0.376,-1.142 -0.059,-0.246 -0.212,-0.458 -0.365,-0.659 -0.411,-0.543 -0.878,-1.088 -1.513,-1.331 -1.698,-0.652 -4.222,0.093 -3.328,2.261 0.831,2.017 3.306,2.463 4.83,0.871"
          style={{
            fill: "#be8d89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path342"
        />
        <path
          d="m 374.163,151.166 c 0.566,0.621 0.976,1.432 0.938,2.271 -0.005,0.1 -0.017,0.204 -0.072,0.287 -0.059,0.089 -0.159,0.142 -0.254,0.192 -0.505,0.262 -1.034,0.53 -1.603,0.535 -0.474,0.004 -0.929,-0.176 -1.36,-0.372 -1.473,-0.672 -4.058,-2.336 -2.702,-4.234 1.383,-1.935 3.936,0.097 5.053,1.321"
          style={{
            fill: "#be8d89",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path344"
        />
        <path
          d="m 400.169,145.628 c 0.477,0.344 0.886,0.784 1.192,1.286 0.129,0.211 0.241,0.48 0.12,0.696 -0.066,0.12 -0.192,0.196 -0.312,0.262 -0.362,0.199 -0.735,0.38 -1.116,0.538 -4.213,1.755 -1.888,-4.223 0.116,-2.782"
          style={{
            fill: "#caa189",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path346"
        />
        <path
          d="m 404.167,123.306 c 0.978,0.988 1.526,2.389 1.477,3.779 -0.004,0.101 -0.012,0.206 -0.06,0.295 -0.057,0.101 -0.159,0.169 -0.262,0.224 -1.669,0.906 -5.227,0.199 -5.464,-2.034 -0.253,-2.393 2.55,-4.041 4.309,-2.264"
          style={{
            fill: "#d4b29d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path348"
        />
        <path
          d="m 393.337,110.486 c 1.727,-0.964 3.769,2.444 1.401,3.451 -2.269,0.966 -3.136,-2.481 -1.401,-3.451"
          style={{
            fill: "#d4b29d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path350"
        />
        <path
          d="m 411.033,110.015 c -0.203,-0.401 -0.478,-0.791 -0.887,-0.979 -0.69,-0.318 -1.545,0.077 -1.976,0.704 -1.018,1.481 -1.036,5.165 1.193,5.626 2.665,0.551 2.375,-3.96 1.67,-5.351"
          style={{
            fill: "#d4b29d",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path352"
        />
      </g>
    </g>
  );
};

export default Pluto;
