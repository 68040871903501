import React from "react";
import { PlanetSVGProps } from "./../types";

const Saturn: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 169.94392}, ${height / 112.14668})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-246.77673,421.93065)"
        id="g8"
      >
        <path
          d="m 290.462,274.393 c 0,-23.226 -18.828,-42.055 -42.055,-42.055 -23.226,0 -42.055,18.829 -42.055,42.055 0,23.226 18.829,42.055 42.055,42.055 23.227,0 42.055,-18.829 42.055,-42.055"
          style={{
            fill: "#ffc062",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path128"
        />
        <path
          d="m 289.265,269.658 c -1.009,0.127 -2.023,-0.177 -2.959,-0.573 -2.353,-0.997 -4.425,-2.594 -6.819,-3.49 -1.578,-0.591 -3.257,-0.861 -4.926,-1.085 -6.915,-0.927 -14.179,-1.068 -20.615,1.623 -2.926,1.224 -5.671,3.027 -8.802,3.532 -3.011,0.484 -6.084,-0.282 -9.129,-0.127 -4.018,0.204 -7.923,2.093 -10.577,5.116 -1.977,2.251 -3.365,5.16 -5.98,6.62 -1.344,0.749 -2.924,1.055 -4.165,1.964 -2.575,1.888 -2.472,7.628 -4.642,9.684 -1.383,-2.813 -2.455,-5.805 -3.185,-8.929 0.512,-0.637 1.096,-1.242 1.368,-2.01 0.231,-0.653 0.212,-1.37 0.4,-2.037 0.367,-1.297 1.456,-2.246 2.536,-3.05 1.747,-1.301 3.615,-2.438 5.573,-3.391 1.099,-0.535 2.235,-1.018 3.221,-1.739 0.991,-0.724 1.797,-1.664 2.681,-2.515 4.744,-4.571 11.553,-6.418 18.137,-6.677 3.067,-0.121 6.15,0.064 9.2,-0.279 5.206,-0.585 10.261,-2.698 15.49,-2.38 4.646,0.282 9.001,2.468 13.638,2.874 3.192,0.279 6.768,-0.182 9.473,1.286 0.437,1.734 0.759,3.512 0.974,5.324 -0.284,0.129 -0.587,0.22 -0.892,0.259"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path130"
        />
        <path
          d="m 281.819,299.921 c -1.825,-0.309 -3.665,-0.608 -5.456,-0.518 -5.299,0.265 -9.894,3.946 -15.158,4.612 -2.817,0.356 -5.665,-0.174 -8.504,-0.208 -2.84,-0.035 -5.91,0.548 -7.837,2.634 -1.13,1.224 -1.738,2.826 -2.587,4.259 -0.605,1.021 -2.313,3.106 -4.155,4.476 -1.809,-0.455 -3.568,-1.033 -5.276,-1.715 0.724,-3.169 6.33,-7.857 8.188,-9.294 2.945,-2.277 6.612,-3.605 10.332,-3.739 2.071,-0.075 4.158,0.207 6.209,-0.087 1.627,-0.233 3.177,-0.822 4.74,-1.329 6.905,-2.24 14.311,-2.891 21.507,-1.951 -0.63,0.981 -1.296,1.937 -2.003,2.86"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path132"
        />
        <path
          d="m 290.288,278.12 c -0.55,-0.022 -1.099,-0.021 -1.648,0.029 -3.845,0.347 -7.207,2.934 -11.048,3.322 -4.567,0.462 -9.502,-2.194 -13.569,-0.066 -1.58,0.827 -2.744,2.272 -4.228,3.261 -4.755,3.166 -11.038,0.94 -16.731,0.464 -0.95,-0.08 -1.953,-0.096 -2.798,0.344 -1.191,0.62 -1.769,1.967 -2.6,3.022 -2.328,2.952 -6.574,3.53 -9.559,5.815 -2.478,1.896 -3.905,4.814 -5.695,7.369 -0.576,0.821 -1.767,2.234 -2.95,3.206 -1.038,-0.986 -2.025,-2.023 -2.958,-3.109 0.637,-2.995 2.405,-6.76 3.896,-8.619 2.001,-2.492 5.477,-3.359 7.645,-5.707 1.139,-1.233 1.85,-2.807 2.96,-4.066 3.545,-4.024 9.753,-3.717 15.115,-3.59 3.085,0.073 6.249,-0.037 9.121,-1.166 1.076,-0.423 2.1,-0.985 3.206,-1.322 2.348,-0.713 4.873,-0.359 7.314,-0.606 2.454,-0.247 4.798,-1.099 7.175,-1.757 5.676,-1.569 11.654,-2.014 17.505,-1.355 0.006,0.268 0.021,0.535 0.021,0.804 0,1.257 -0.066,2.498 -0.174,3.727"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path134"
        />
        <path
          d="m 277.707,250.041 c -2.4,-0.675 -4.769,-1.767 -7.257,-1.619 -1.808,0.107 -3.526,0.868 -5.333,1.017 -5.219,0.43 -10.393,-4.228 -15.26,-2.292 -1.217,0.485 -2.241,1.341 -3.298,2.115 -3.68,2.693 -8.006,4.495 -12.509,5.213 -2.503,0.399 -5.118,0.484 -7.407,1.574 -3.043,1.451 -5.007,4.419 -7.28,6.909 -3.477,3.812 -7.973,6.671 -12.879,8.26 0.117,-1.565 0.317,-3.106 0.601,-4.618 2.54,-1.502 4.426,-4.191 6.054,-6.755 2.098,-3.303 4.428,-6.89 8.149,-8.099 3.033,-0.986 6.479,-0.177 9.417,-1.416 2.376,-1.002 4.021,-3.172 6.06,-4.752 3.678,-2.85 8.554,-3.691 13.207,-3.616 4.653,0.075 9.258,0.966 13.908,1.171 3.901,0.171 7.806,-0.143 11.697,-0.486 0.12,-0.009 0.24,-0.02 0.36,-0.03 2.784,2.413 5.253,5.177 7.324,8.236 -1.876,-0.038 -3.747,-0.305 -5.554,-0.812"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path136"
        />
        <path
          d="m 290.43,274.643 c 7.597,-4.878 11.711,-9.626 10.673,-13.241 -2.231,-7.779 -27.452,-7.369 -56.332,0.916 -28.88,8.285 -50.482,21.306 -48.251,29.085 0.812,2.831 4.673,4.576 10.645,5.253 -12.28,0.176 -20.541,-2.063 -21.89,-6.764 -2.711,-9.452 23.537,-25.274 58.627,-35.34 35.09,-10.066 65.735,-10.564 68.446,-1.113 1.622,5.654 -7.121,13.587 -21.918,21.204"
          style={{
            fill: `${foreground}`,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path138"
        />
        <path
          d="m 302.948,267.087 c -3.411,2.491 -7.632,5.04 -12.518,7.556 7.597,-4.878 11.711,-9.626 10.673,-13.241 -2.231,-7.779 -27.452,-7.369 -56.332,0.916 -28.88,8.285 -50.482,21.306 -48.251,29.085 0.731,2.549 3.939,4.215 8.929,5.016 -6.078,-0.796 -9.996,-2.668 -10.846,-5.632 -2.432,-8.476 21.109,-22.666 52.581,-31.695 31.471,-9.028 58.956,-9.475 61.386,-0.998 0.703,2.448 -1.586,5.697 -5.622,8.993"
          style={{
            fill: "#f5e2d0",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path140"
        />
        <path
          d="m 240.53,288.732 c 0.315,-0.462 0.667,-0.898 1.053,-1.303 0.238,-0.25 0.497,-0.494 0.815,-0.63 0.327,-0.139 0.693,-0.154 1.047,-0.124 0.741,0.064 1.464,0.322 2.078,0.741 0.118,0.08 0.238,0.174 0.281,0.31 0.043,0.136 -0.001,0.284 -0.059,0.414 -0.239,0.538 -0.706,0.951 -1.229,1.222 -0.523,0.271 -1.102,0.414 -1.679,0.53 -0.658,0.133 -1.37,0.234 -2.034,0.087 -0.191,-0.043 -0.845,-0.185 -0.892,-0.415 -0.037,-0.186 0.496,-0.652 0.619,-0.832"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path142"
        />
        <path
          d="m 266.662,281.719 c 0.321,-0.034 0.648,-0.007 0.962,0.067 0.245,0.058 0.49,0.149 0.673,0.323 0.317,0.305 0.369,0.825 0.184,1.224 -0.183,0.4 -0.564,0.685 -0.979,0.833 -0.811,0.288 -2.844,0.43 -3.122,-0.699 -0.261,-1.055 1.502,-1.665 2.282,-1.748"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path144"
        />
        <path
          d="m 264.818,265.227 c 0.424,0.022 0.856,0.045 1.254,0.193 0.398,0.147 0.765,0.441 0.894,0.845 0.171,0.536 -0.125,1.137 -0.577,1.473 -0.453,0.335 -1.027,0.456 -1.585,0.525 -1.075,0.132 -6.009,0.294 -5.653,-1.636 0.343,-1.856 4.408,-1.466 5.667,-1.4"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path146"
        />
        <path
          d="m 251.73,306.325 c 0.506,0.115 1.046,0.445 1.086,0.962 0.025,0.301 -0.131,0.588 -0.314,0.831 -0.736,0.975 -3.503,2.394 -4.214,0.635 -0.706,-1.746 2.222,-2.704 3.442,-2.428"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path148"
        />
        <path
          d="m 279.42,294.082 c 0.35,-0.308 0.496,-0.793 0.505,-1.259 0.006,-0.318 -0.047,-0.64 -0.185,-0.927 -0.26,-0.543 -0.805,-0.91 -1.385,-1.074 -0.579,-0.165 -1.194,-0.151 -1.794,-0.099 -1.146,0.099 -6.93,0.858 -4.519,2.81 0.916,0.742 2.002,0.854 3.124,0.8 1.144,-0.054 3.319,0.572 4.254,-0.251"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path150"
        />
        <path
          d="m 235.47,272.773 c 0.398,0.022 0.843,0.13 1.182,0.336 0.312,0.19 0.535,0.463 0.547,0.83 0.01,0.32 -0.154,0.617 -0.333,0.882 -1.219,1.809 -3.803,3.457 -5.498,1.243 -2.007,-2.622 2.162,-3.398 4.102,-3.291"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path152"
        />
        <path
          d="m 230.17,246.159 c 0.879,-0.751 1.439,-2.073 0.864,-3.076 -0.369,-0.645 -1.122,-0.995 -1.863,-1.056 -1.857,-0.152 -5.293,0.926 -4.63,3.391 0.637,2.362 4.159,1.994 5.629,0.741"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path154"
        />
        <path
          d="m 262.383,240.877 c 0.223,-0.034 0.454,-0.08 0.632,-0.219 0.35,-0.272 0.37,-0.804 0.238,-1.226 -0.209,-0.668 -0.677,-1.174 -1.26,-1.564 -0.579,-0.387 -1.27,-0.659 -1.934,-0.862 -1.758,-0.537 -5.174,-1.677 -3.557,1.166 1.145,2.015 3.694,3.047 5.881,2.705"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path156"
        />
        <path
          d="m 217.992,288.701 c -0.576,0.409 -1.375,0.748 -1.955,0.344 l -0.608,-0.236 c -0.624,-0.777 -0.543,-2.026 0.176,-2.716 0.475,-0.455 1.135,-0.649 1.768,-0.827 0.164,-0.046 0.341,-0.092 0.502,-0.035 0.125,0.044 0.22,0.144 0.31,0.24 0.45,0.481 0.93,1.04 0.896,1.698 -0.034,0.648 -0.561,1.156 -1.089,1.532"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path158"
        />
        <path
          d="m 229.187,296.306 c 0.522,-0.139 1.208,-0.176 1.468,0.298 0.069,0.125 0.094,0.271 0.108,0.414 0.155,1.56 -1.371,3.973 -3.188,2.991 -2,-1.081 0.274,-3.346 1.612,-3.703"
          style={{
            fill: "#ffd587",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path160"
        />
        <path
          d="m 259.812,294.841 c -1.031,-1.886 -6.178,-1.711 -5.181,1.322 0.994,3.022 6.606,1.285 5.181,-1.322"
          style={{
            fill: "#ffaa62",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path162"
        />
      </g>
    </g>
  );
};

export default Saturn;
