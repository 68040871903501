import React from "react";
import { PlanetSVGProps } from "./../types";

const Moon: React.FC<PlanetSVGProps> = ({
  x,
  y,
  width,
  height,
  foreground,
}) => {
  return (
    <g
      transform={`translate(${x}, ${y}) scale(${width / 97.533867}, ${height / 97.533333})`}
    >
      <g
        transform="matrix(1.3333333,0,0,-1.3333333,-102.20879,605.55732)"
        id="g8"
      >
        <path
          d="m 149.807,417.593 c 0,-20.2 -16.375,-36.575 -36.575,-36.575 -20.2,0 -36.5754,16.375 -36.5754,36.575 0,20.2 16.3754,36.575 36.5754,36.575 20.2,0 36.575,-16.375 36.575,-36.575"
          style={{
            display: "inline",
            fill: "#a3aeba",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path18"
        />
        <path
          d="m 141.068,426.641 c 0.459,-0.905 0.58,-1.938 0.753,-2.939 0.254,-1.46 0.634,-2.898 1.135,-4.293 0.343,-0.958 1.14,-2.091 2.101,-1.755 1.136,0.396 1.399,2.6 2.542,2.223 0.36,-0.118 0.66,-0.51 1.027,-0.415 0.284,0.073 0.408,0.398 0.486,0.68 0.165,0.597 0.311,1.205 0.426,1.819 -2.161,18.139 -17.586,32.207 -36.306,32.207 -3.232,0 -6.365,-0.424 -9.35,-1.211 -0.405,-1.664 -0.678,-3.76 -0.483,-5.112 0.275,-1.898 0.905,-1.331 2.465,-1.826 1.42,-0.451 2.305,-1.408 3.201,-2.567 0.464,-0.6 0.943,-1.234 1.062,-1.983 0.299,-1.896 -1.755,-3.375 -2.051,-5.271 -0.187,-1.198 0.355,-2.377 0.906,-3.457 0.517,-1.012 1.104,-2.066 2.089,-2.632 0.986,-0.568 2.481,-0.399 2.97,0.627 2.98,-1.997 6.278,-3.518 9.731,-4.488 0.704,-0.198 1.466,-0.371 2.152,-0.12 1.107,0.405 1.55,1.692 1.981,2.788 0.432,1.097 1.264,2.312 2.438,2.205 1.67,-0.153 2.268,-2.824 3.944,-2.851 0.53,-0.009 1.015,0.269 1.512,0.451 1.94,0.707 4.335,-0.239 5.269,-2.08"
          style={{
            display: "inline",
            fill: "#93a1af",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path20"
        />
        <path
          d="m 113.232,454.168 c -0.782,0 -1.557,-0.026 -2.326,-0.075 0.201,-0.577 0.486,-1.126 0.847,-1.609 0.264,-0.354 0.736,-0.654 0.956,-1.012 0.453,-0.737 0.25,-0.464 0.374,-1.312 0.195,-1.334 0.477,-2.055 1.155,-3.292 1.289,-2.352 1.418,-5.188 -0.298,-7.4 -0.334,-0.431 -0.735,-0.842 -0.865,-1.371 -0.279,-1.138 0.916,-2.204 2.087,-2.258 1.171,-0.053 2.251,0.574 3.255,1.18 0.625,-1.516 0.051,-3.417 0.977,-4.769 0.967,-1.414 3.257,-1.406 4.533,-0.263 0.47,0.421 0.864,0.976 1.463,1.174 1.219,0.402 2.725,-0.853 3.737,-0.063 0.276,0.216 0.438,0.546 0.687,0.793 0.512,0.509 1.4,0.577 1.985,0.154 0.584,-0.424 0.796,-1.289 0.472,-1.934 1.555,-0.197 3.11,-0.395 4.666,-0.592 0.668,-0.085 1.355,-0.176 1.947,-0.5 0.712,-0.39 1.206,-1.074 1.676,-1.735 1.315,-1.852 2.631,-3.704 3.947,-5.557 0.458,-0.644 0.978,-1.332 1.743,-1.531 0.801,-0.207 1.663,0.211 2.197,0.843 0.324,0.382 0.542,0.834 0.717,1.307 -3.17,16.971 -18.042,29.822 -35.932,29.822"
          style={{
            display: "inline",
            fill: "#8996a3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path22"
        />
        <path
          d="m 144.544,436.137 c 0.036,0.053 0.067,0.109 0.1,0.165 -4.694,7.874 -12.26,13.83 -21.255,16.428 0.002,-0.009 0.002,-0.017 0.004,-0.027 0.267,-1.307 0.573,-1.524 -0.229,-2.711 -0.62,-0.918 -1.078,-1.761 -0.976,-2.918 0.013,-0.153 0.041,-0.316 0.148,-0.427 0.175,-0.183 0.508,-0.165 0.647,-0.376 0.092,-0.139 0.062,-0.322 0.016,-0.482 -0.188,-0.635 -0.591,-1.187 -0.817,-1.809 -0.227,-0.624 -0.229,-1.416 0.274,-1.848 0.26,-0.224 0.641,-0.34 0.778,-0.656 0.073,-0.169 0.058,-0.361 0.074,-0.545 0.061,-0.714 0.607,-1.312 1.245,-1.636 0.638,-0.324 1.364,-0.422 2.074,-0.515 0.445,-0.058 0.89,-0.117 1.334,-0.175 1.535,-0.202 3.07,-0.404 4.611,-0.54 0.515,-0.044 1.057,-0.092 1.482,-0.385 0.426,-0.293 0.665,-0.925 0.359,-1.341 0.494,0.234 1.094,0.024 1.528,-0.309 0.434,-0.332 0.772,-0.778 1.208,-1.109 0.413,-0.314 0.901,-0.514 1.399,-0.656 2.155,-0.612 4.757,0.006 5.996,1.872"
          style={{
            display: "inline",
            fill: "#7e8a96",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path24"
        />
        <path
          d="m 113.105,389.179 c -1.878,1.418 -3.038,3.565 -4.14,5.644 -0.263,0.497 -0.534,1.02 -0.535,1.583 -0.001,0.948 0.749,1.734 0.927,2.664 0.205,1.068 -0.365,2.116 -0.527,3.191 -0.261,1.729 0.541,3.421 0.836,5.144 0.295,1.724 -0.245,3.919 -1.939,4.349 1.07,0.531 2.177,1.093 2.914,2.034 0.737,0.941 0.984,2.38 0.239,3.314 -0.447,0.561 -1.152,0.839 -1.823,1.093 -1.842,0.698 -3.683,1.395 -5.524,2.092 -0.742,0.281 -1.639,0.543 -2.273,0.067 -0.484,-0.365 -0.617,-1.027 -0.969,-1.52 -0.916,-1.277 -2.8754,-1.012 -4.3508,-0.469 -1.4746,0.544 -3.1929,1.214 -4.5218,0.373 -0.0539,-1.474 -1.1766,-2.695 -2.4004,-3.518 -1.6711,-1.124 -3.8266,-1.773 -5.7168,-1.078 -1.8907,0.694 -3.1657,3.09 -2.1797,4.846 -2.0649,2.131 -3.0469,4.648 -3.4035,7.353 -0.6887,-2.803 -1.0614,-5.732 -1.0614,-8.748 0,-20.2 16.3754,-36.575 36.5754,-36.575 0.389,0 0.773,0.017 1.159,0.029 0.477,0.466 0.892,0.988 1.206,1.579 0.797,1.497 0.831,3.482 -0.248,4.79 -0.607,0.736 -1.482,1.188 -2.244,1.763"
          style={{
            display: "inline",
            fill: "#93a1af",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path26"
        />
        <path
          d="m 78.9504,404.83 c 3.7996,-10.202 12.0226,-18.243 22.3376,-21.806 0.211,0.257 0.408,0.551 0.593,0.885 0.626,1.132 1.035,2.546 0.412,3.774 -0.457,0.902 -2.4883,1.523 -2.3688,2.59 0.0403,0.353 0.3168,0.651 0.3388,1.006 0.047,0.753 -0.9349,1.056 -1.4681,1.589 -0.8515,0.851 -0.4594,2.364 0.3668,3.239 0.8262,0.876 1.9703,1.385 2.8593,2.196 2.449,2.234 2.105,6.772 -0.652,8.613 -0.621,0.414 -1.329,0.704 -1.8909,1.197 -0.6945,0.608 -1.1074,1.477 -1.7484,2.142 -1.9324,2.007 -5.8758,1.229 -6.9012,-1.361 -1.9402,-0.344 -3.6176,-1.882 -4.1269,-3.785 -0.1078,-0.402 -0.1848,-0.85 -0.5,-1.122 -0.1317,-0.115 -0.295,-0.187 -0.4598,-0.245 -2.1926,-0.769 -4.5016,0.83 -6.7914,1.088"
          style={{
            display: "inline",
            fill: "#8996a3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path28"
        />
        <path
          d="m 92.8848,387.222 c 0.1402,0.044 0.2816,0.083 0.4261,0.11 0.6371,0.12 1.2965,0.047 1.9379,0.143 0.6418,0.095 1.3172,0.421 1.5379,1.03 0.3117,0.864 -0.414,1.726 -1.0793,2.36 -0.4719,0.449 -0.9441,0.898 -1.4164,1.348 -1.1285,1.074 -2.3457,2.361 -2.2515,3.916 0.0617,1.008 0.6613,2.117 0.0839,2.946 -0.3211,0.462 -0.9089,0.645 -1.4586,0.769 -0.3039,0.069 -0.621,0.129 -0.9261,0.065 -1.325,-0.28 -1.336,-2.438 -2.5789,-2.974 -1.0118,-0.437 -2.0321,0.412 -3.0567,0.29 -0.3429,-0.04 -0.6754,-0.175 -0.9824,-0.366 2.6188,-3.795 5.9328,-7.067 9.7641,-9.637"
          style={{
            display: "inline",
            fill: "#7e8a96",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path30"
        />
        <path
          d="m 126.279,416.452 c 1.114,-0.611 2.455,0.057 3.646,0.498 1.432,0.529 2.989,0.718 4.506,0.548 1.183,-1.54 2.367,-3.08 3.55,-4.621 0.269,-0.348 0.546,-0.721 0.594,-1.158 0.076,-0.698 -0.441,-1.299 -0.743,-1.931 -0.966,-2.021 0.1,-4.911 -1.608,-6.361 -0.72,-0.612 -1.737,-0.738 -2.534,-1.245 -0.946,-0.602 -1.477,-1.661 -2.035,-2.634 -0.79,-1.38 -1.718,-2.68 -2.766,-3.875 -0.321,-0.366 -0.675,-0.737 -1.138,-0.885 -0.538,-0.173 -1.12,-0.016 -1.663,0.141 -1.362,0.395 -2.725,0.789 -4.088,1.184 -0.792,0.229 -1.624,0.483 -2.187,1.084 -0.563,0.602 -0.715,1.671 -0.092,2.211 0.382,0.331 0.962,0.398 1.281,0.79 0.418,0.514 0.162,1.304 -0.27,1.807 -1.074,1.252 -3.023,1.624 -3.824,3.066 -0.611,1.097 -0.349,2.449 -0.142,3.687 0.483,2.885 -0.877,8.959 1.854,10.824 1.173,0.801 2.774,0.719 4.052,0.236 1.832,-0.692 2.067,-2.522 3.607,-3.366"
          style={{
            display: "inline",
            fill: "#93a1af",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path32"
        />
        <path
          d="m 126.726,413.299 c 0.724,-0.397 1.595,0.038 2.37,0.324 0.93,0.343 1.942,0.467 2.928,0.357 0.769,-1.001 1.538,-2.003 2.307,-3.004 0.174,-0.226 0.355,-0.469 0.386,-0.753 0.05,-0.452 -0.286,-0.843 -0.483,-1.255 -0.628,-1.313 0.065,-3.191 -1.045,-4.134 -0.468,-0.396 -1.128,-0.479 -1.647,-0.809 -0.615,-0.391 -0.959,-1.079 -1.322,-1.711 -0.514,-0.896 -1.117,-1.742 -1.798,-2.519 -0.209,-0.237 -0.438,-0.479 -0.739,-0.575 -0.35,-0.112 -0.728,-0.01 -1.08,0.092 -0.886,0.256 -1.772,0.513 -2.658,0.77 -0.514,0.148 -1.055,0.313 -1.421,0.704 -0.366,0.391 -0.465,1.085 -0.06,1.437 0.248,0.215 0.625,0.258 0.833,0.513 0.271,0.334 0.105,0.848 -0.175,1.175 -0.699,0.813 -1.965,1.054 -2.486,1.992 -0.397,0.713 -0.227,1.592 -0.092,2.396 0.313,1.875 -0.57,5.822 1.205,7.035 0.762,0.52 1.802,0.467 2.633,0.154 1.191,-0.451 1.343,-1.64 2.344,-2.189"
          style={{
            display: "inline",
            fill: "#8996a3",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path34"
        />
        <path
          d="m 86.8559,419.558 c 0.3808,0.247 0.6222,0.654 0.875,1.031 0.6379,0.95 1.5722,1.862 2.7164,1.863 1.223,10e-4 2.389,-1.047 3.5488,-0.66 0.7418,0.247 1.1926,1.04 1.2738,1.817 0.0817,0.778 -0.1347,1.552 -0.3484,2.304 -0.0184,0.063 -0.0375,0.13 -0.0848,0.175 -0.0633,0.062 -0.1597,0.073 -0.2484,0.078 -0.9496,0.061 -1.9086,-0.024 -2.8332,-0.25 -2.2395,-0.546 -4.0156,3.098 -5.2727,0.313 -0.4469,-0.99 -2.0992,-8.276 0.3735,-6.671"
          style={{
            display: "inline",
            fill: "#93a1af",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path36"
        />
        <path
          d="m 100.24,441.148 3.426,0.594 c 0,0 2.211,1.187 1.173,-1.781 -1.039,-2.966 -1.039,-3.266 -0.593,-4.081 0.444,-0.814 4.45,-4.226 2.67,-5.413 -1.78,-1.186 -3.582,-1.483 -3.582,-1.483 l -5.6156,3.709 c 0,0 -7.3528,-0.594 -7.3106,-0.148 0.0414,0.444 0.8996,5.61 0.8996,5.61 h 3.2957 l 2.2582,2.993 h 3.3787"
          style={{
            display: "inline",
            fill: "#93a1af",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.1,
          }}
          id="path38"
        />
      </g>
    </g>
  );
};

export default Moon;
