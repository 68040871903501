import React from "react";
import { Box } from "@mui/material";
import { Astrolabe } from "../components/Astrolabe/Astrolabe";

const Home: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 129px)", // 64px (Navbar) + 32px (top margin) + 32px (bottom margin) + 1
        minHeight: "750px",
        margin: "32px 0",
      }}
    >
      <Box width={750} height={750}>
        <Astrolabe r0={180} r1={220} r2={250} r3={260} r4={280} r5={300} />
      </Box>
    </div>
  );
};

export default Home;
