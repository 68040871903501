import React, { useMemo, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { fetchEclipticPositions } from "./../../services/authService";
import { toRad, anglesDays, anglesMonths } from "./Math";

import {
  ariesPath,
  taurusPath,
  geminiPath,
  cancerPath,
  leoPath,
  virgoPath,
  libraPath,
  scorpioPath,
  ophiochusPath,
  sagittariusPath,
  capricornPath,
  aquariusPath,
  piscesPath,
  circlePath,
  drawCircle,
  tickX,
  tickY,
  drawTiltedText,
  drawTiltedTick,
  drawObjects,
} from "./Zodiac";

const seasons = [
  { label: "Spring", start: toRad(0), textAngle: 45 },
  { label: "Summer", start: toRad(90), textAngle: 135 },
  { label: "Fall", start: toRad(180), textAngle: 225 },
  { label: "Winter", start: toRad(270), textAngle: 315 },
];

const constellations = [
  { name: "Aries", sign: "♈︎", start: toRad(29), textAngle: 41 },
  { name: "Taurus", sign: "♉︎", start: toRad(53), textAngle: 71.5 },
  { name: "Gemini", sign: "♊︎", start: toRad(90), textAngle: 104 },
  { name: "Cancer", sign: "♋︎", start: toRad(118), textAngle: 128 },
  { name: "Leo", sign: "♌︎", start: toRad(138), textAngle: 155.5 },
  { name: "Virgo", sign: "♍︎", start: toRad(173), textAngle: 195.5 },
  { name: "Libra", sign: "♎︎", start: toRad(218), textAngle: 229.5 },
  { name: "Scorpio", sign: "♏︎", start: toRad(241), textAngle: 244 },
  { name: "Ophiochus", sign: "⛎", start: toRad(247), textAngle: 256.5 },
  { name: "Sagittarius", sign: "♐︎", start: toRad(266), textAngle: 282.5 },
  { name: "Capricorn", sign: "♑︎", start: toRad(299), textAngle: 313 },
  { name: "Aquarius", sign: "♒︎", start: toRad(327), textAngle: 339 },
  { name: "Pisces", sign: "♓︎", start: toRad(351), textAngle: 10 },
];

interface Props {
  r0: number;
  r1: number;
  r2: number;
  r3: number;
  r4: number;
  r5: number;
}

export const Astrolabe: React.FC<Props> = ({ r0, r1, r2, r3, r4, r5 }) => {
  const [positions, setPositions] = useState({});
  const theme = useTheme();
  const fg = theme.palette.secondary.main;

  useEffect(() => {
    const intervalId = setInterval(
      async () => {
        try {
          const date = new Date().toISOString();
          const data = await fetchEclipticPositions(date);
          setPositions(data.positions);
        } catch (err) {
          console.error(err);
        }
      },
      5 * 60 * 1000,
    );

    // Initial fetch
    (async () => {
      try {
        const date = new Date().toISOString();
        const data = await fetchEclipticPositions(date);
        setPositions(data.positions);
      } catch (err) {
        console.error(err);
      }
    })();

    return () => clearInterval(intervalId);
  }, []);

  const lineDays = useMemo(
    () =>
      anglesDays.map((a) =>
        drawTiltedTick(r2, r3, a, fg, `tilted-tick-360-${r2}-${r3}-${a}`),
      ),
    [r2, r3, fg],
  );

  return (
    <React.Fragment>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
        <defs>
          {circlePath((r4 + r5) / 2 + 5, "season-circle", fg)}
          {circlePath((r1 + r2) / 2 + 5, "constellation-circle", fg)}
          <path
            id="Aries"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={ariesPath}
          />
          <path
            id="Taurus"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={taurusPath}
          />
          <path
            id="Gemini"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={geminiPath}
          />
          <path
            id="Cancer"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={cancerPath}
          />
          <path id="Leo" stroke={fg} fill={fg} strokeWidth="1" d={leoPath} />
          <path
            id="Virgo"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={virgoPath}
          />
          <path
            id="Libra"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={libraPath}
          />
          <path
            id="Scorpio"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={scorpioPath}
          />
          <path
            id="Ophiochus"
            stroke={fg}
            fill="none"
            strokeWidth="7"
            d={ophiochusPath}
          />
          <path
            id="Sagittarius"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={sagittariusPath}
          />
          <path
            id="Capricorn"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={capricornPath}
          />
          <path
            id="Aquarius"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={aquariusPath}
          />
          <path
            id="Pisces"
            stroke={fg}
            fill={fg}
            strokeWidth="1"
            d={piscesPath}
          />
        </defs>
        <g>
          {drawCircle(r0, "50%", "50%", fg)}
          {drawCircle(r1, "50%", "50%", fg)}
          {drawCircle(r2, "50%", "50%", fg)}
          {drawCircle(r3, "50%", "50%", fg)}
          {drawCircle(r4, "50%", "50%", fg)}
          {drawCircle(r5, "50%", "50%", fg)}
        </g>
        <g>{lineDays}</g>
        <g>
          {anglesMonths.map((a) =>
            drawTiltedTick(r3, r4, a, fg, `tilted-tick-${r2}-${r3}-${a}`),
          )}
          {anglesMonths.map((a, index) => {
            const monthShortNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            return drawTiltedText(
              (r3 + r4) / 2 + 3,
              a + toRad(1),
              monthShortNames[(index + 1) % 12],
              "16px",
              fg,
            );
          })}
        </g>
        <g>
          {seasons.map((s) =>
            drawTiltedTick(
              r4,
              r5,
              s.start,
              fg,
              `tilted-tick-${r2}-${r3}-${s.start}`,
            ),
          )}
          {seasons.map((s) => (
            <text
              fill={fg}
              fontSize="16px"
              transform={
                "rotate(" + (-90 - s.textAngle).toString() + " 300 300)"
              }
            >
              <textPath xlinkHref="#season-circle">{s.label}</textPath>
            </text>
          ))}
        </g>
        <g>
          {constellations.map((c) =>
            drawTiltedTick(
              r1,
              r2,
              c.start,
              fg,
              `tilted-tick-${r2}-${r3}-${c.start}`,
            ),
          )}
          {constellations.map((c) => (
            <use
              xlinkHref={`#${c.name}`}
              transform={`translate(${tickX((r1 + r2) / 2, toRad(c.textAngle))} ${tickY((r1 + r2) / 2, toRad(c.textAngle))}) rotate(${-c.textAngle + 90} 0 0) scale(0.3)`}
            />
          ))}
        </g>
        {drawObjects(r0, positions, fg)}
      </svg>
    </React.Fragment>
  );
};
